import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Grid,
  TextField,
  LinearProgress,
  Input,
  Paper,
  Modal,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { blue, red } from "@mui/material/colors";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { useTheme } from "@mui/material";
import {
  getUserFromLocalStorage,
  getResFromLocalStorage,
} from "../utils/localStorage";
import ManagersTable from "./ManagersTable";
import Campaign from "./Campaign";
import account from "src/_mock/account";
import { Image } from "react-bootstrap";
import "./userStyle.css";
import PostBackMiniCard from "src/components/UserDetails/PostBackMiniCard";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IosShareIcon from "@mui/icons-material/IosShare";
import PhoneIcon from "@mui/icons-material/Phone";
import skypeSvg from "./../images/skype.svg";
import EditIcon from "@mui/icons-material/Edit";
import LanguageIcon from "@mui/icons-material/Language";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import SnapChatIcon from "./../images/snapchat.svg";
import TickTockIcon from "./../images/ticktok.svg";
import RedditIcon from "@mui/icons-material/Reddit";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// Constants for dropdown options
const EXPERIENCE_OPTIONS = [
  "Beginner (0-1 year)",
  "Intermediate (1-3 years)", 
  "Advanced (3-5 years)",
  "Expert (5+ years)"
];

const DEALS_OPTIONS = [
  "Content Creation",
  "Affiliate Marketing",
  "Product Reviews", 
  "Event Coverage",
  "Sponsored Posts",
  "Consulting",
  "Brand Ambassadorship",
  "Social Media Management"
];

const AGE_RANGE_OPTIONS = [
  "13-17",
  "18-24",
  "25-34",
  "35-44", 
  "45-54",
  "55-64",
  "65+"
];

const GENDER_OPTIONS = [
  "Male",
  "Female",
  "All"
];

const ENGAGEMENT_RATE_OPTIONS = [
  "Less than 1%",
  "1% - 2%",
  "2% - 3%",
  "3% - 5%",
  "5% - 10%",
  "More than 10%"
];

const CONTENT_TYPES_OPTIONS = [
  "Photos",
  "Videos",
  "Reels/Short-form",
  "Blog Posts",
  "Long-form Videos",
  "Reviews",
  "Tutorials",
  "Podcasts",
  "Live Streams"
];

const CONTENT_NICHES_OPTIONS = [
  "Gaming",
  "Business",
  "Fashion",
  "Tech",
  "Lifestyle",
  "Beauty",
  "Food",
  "Fitness",
  "Travel",
  "Health",
  "Entertainment",
  "Education"
];

const FREQUENCY_OPTIONS = [
  "Daily",
  "2-4 times per week",
  "Weekly",
  "Bi-weekly",
  "Monthly"
];

const CURRENCY_OPTIONS = [
  "USD",
  "EUR",
  "GBP",
  "INR",
  "AUD",
  "CAD"
];

const COUNTRIES_OPTIONS = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    background: 'linear-gradient(120deg, #f6f9fc 0%, #edf2f7 100%)',
    minHeight: '100vh',
    padding: {
      xs: '1rem',
      sm: '2rem'
    },
  },
  mainCard: {
    background: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  profileSection: {
    background: 'linear-gradient(120deg, #2c3e50 0%, #3498db 100%)',
    padding: {
      xs: '1.5rem 1rem',
      sm: '2rem'
    },
    color: '#ffffff',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '40px',
      background: 'linear-gradient(180deg, transparent 0%, rgba(0,0,0,0.1) 100%)',
    }
  },
  profileImage: {
    width: {
      xs: '100px',
      sm: '120px'
    },
    height: {
      xs: '100px',
      sm: '120px'
    },
    borderRadius: '50%',
    border: '4px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    }
  },
  profileImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem'
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: {
      xs: 'center',
      md: 'left'
    }
  },
  profileName: {
    fontSize: {
      xs: '1.5rem',
      sm: '1.8rem'
    },
    fontWeight: 700,
    marginBottom: '0.5rem',
    color: '#ffffff',
    textShadow: '0 2px 4px rgba(0,0,0,0.1)'
  },
  profileEmail: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '0.5rem',
    fontSize: '1rem',
    justifyContent: {
      xs: 'center',
      md: 'flex-start'
    }
  },
  profileLocation: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '1rem',
    justifyContent: {
      xs: 'center',
      md: 'flex-start'
    }
  },
  profileButtons: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%'
  },
  button: {
    padding: '0.75rem 1.5rem',
    borderRadius: '8px',
    fontSize: '0.9rem',
    fontWeight: 600,
    textTransform: 'none',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
    }
  },
  memberButton: {
    background: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(10px)',
    color: '#ffffff',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.25)',
    }
  },
  resetButton: {
    background: '#e74c3c',
    color: '#ffffff',
    '&:hover': {
      background: '#c0392b',
    }
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      sm: 'repeat(3, 1fr)',
      md: 'repeat(5, 1fr)'
    },
    gap: '1.5rem',
    padding: {
      xs: '1.5rem 1rem',
      sm: '2rem'
    },
    background: '#f8fafc'
  },
  statsCard: {
    background: '#ffffff',
    borderRadius: '16px',
    padding: '1.5rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.03)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.06)',
    }
  },
  tabsContainer: {
    padding: {
      xs: '1rem',
      sm: '1.5rem 2rem'
    },
    borderBottom: '1px solid #e2e8f0',
    background: '#ffffff'
  },
  tabButton: {
    padding: {
      xs: '0.75rem 1.25rem',
      sm: '1rem 2rem'
    },
    minWidth: {
      xs: 'auto',
      sm: '120px'
    },
    borderRadius: '12px',
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'none',
    transition: 'all 0.3s ease',
    color: '#64748b',
    '&.active': {
      background: 'linear-gradient(120deg, #2c3e50 0%, #3498db 100%)',
      color: '#ffffff',
      boxShadow: '0 4px 12px rgba(44, 62, 80, 0.15)',
    },
    '&:hover:not(.active)': {
      background: '#f1f5f9',
      color: '#1e293b',
    }
  },
  formSection: {
    padding: {
      xs: '1.5rem 1rem',
      sm: '2rem'
    }
  },
  boxstyleForm: {
    background: '#ffffff',
    borderRadius: '16px',
    padding: '2rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    marginBottom: '2rem'
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      transition: 'all 0.3s ease',
      '&:hover fieldset': {
        borderColor: '#3498db',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2c3e50',
      }
    },
    '& .MuiInputLabel-root': {
      color: '#64748b',
      '&.Mui-focused': {
        color: '#2c3e50',
      }
    }
  },
  verifiedBadge: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    borderRadius: '8px',
    background: 'rgba(72, 187, 120, 0.1)',
    color: '#48bb78',
    fontSize: '0.875rem',
    fontWeight: 600,
    gap: '0.5rem'
  },
  unverifiedBadge: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    borderRadius: '8px',
    background: 'rgba(245, 101, 101, 0.1)',
    color: '#f56565',
    fontSize: '0.875rem',
    fontWeight: 600,
    gap: '0.5rem'
  }
}));

function UserDetails() {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  const [data, setData] = useState([]);
  const [affiliateData, setAffiliateData] = useState([]);
  const [verificationSent, setVerificationSent] = useState(false);
  const URL = process.env.REACT_APP_PROD_ADMIN_API;
  const URL2 = process.env.REACT_APP_PROD_API;
  const urlVerifyMail = `${URL2}/api/affiliates/send_verification_mail`;
  const urlVerifyAfterMail = `${URL2}/api/affiliates/`;
  const [checkEmailVerifiedStatus, setCheckEmailVerifiedStatus] =
    useState(false);
  const [affData, setAffData] = useState({});
  const [loading, setLoading] = useState(false);
  const user2 = getUserFromLocalStorage();
  const accessToken = user2?.data.access_token;
  const [image, setImage] = useState(null);
  const [affiliate, setAffiliate] = useState();
  const [nameBeni, setNameBeni] = useState();
  const [last, setLast] = useState();
  const [username, setUserName] = useState();
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postCode, setPostCode] = useState();
  const [skype, setSkype] = useState("");
  const [age, setAge] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const urlWallet = `${URL2}/api/analytics/wallet`;
  const [walletData, setWalletData] = useState([]);
  const [campaignData, setCampaignData] = useState();
  const [campaginName, setCampaginName] = useState("");
  const [affiliateAdditionalInfo, setAffiliateAdditionalInfo] = useState({});
  const [b, setB] = useState({});
  const [verified , setVerified] = useState(false);
  const [mainProfile , setMainProfile] = useState("");
  const [cardData, setCardData] = useState({
    contentCreationExperience: "",
    preferredTypesOfDeals: [],
    primaryAudienceAgeRange: [],
    primaryAudienceGender: [],
    averageEngagementRate: "",
    contentTypes: [],
    contentNiches: [],
    contentCreationFrequency: "",
    currency: "",
    currentLocation: "",
    audienceCountries: []
  });

  const theme = useTheme();
  const url1 = `${URL2}/api/affiliates`;
  const url_image = `${URL2}/api/affiliates/update_profile_image`;
  const url_get_aff_info = `${URL2}/api/affiliates/additional_info`;
  const user1 = getResFromLocalStorage();
  const date = new Date(affiliateData.created_at).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: false,
  });
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getTabClass = (tab) => {
    return tab === activeTab ? "active-tab" : "inactive-tab";
  };

  const sendVerificationEmail = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(urlVerifyMail, {
        method: "GET",
        mode: "cors",
        headers: headers,
      });
      console.log("RESPONSE:__", response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setVerificationSent(true);

      console.log("RESPONSE:", jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkEmailVerified = async () => {
    try {
      const response  = await axios.get(urlVerifyAfterMail, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      console.log("RESPONSE:__", response?.data);
      setAffData(response?.data);

      if(response.status ==200){
        if(response?.data?.verified){setVerified(true)}

        setMainProfile(response?.data?.profile_pic);
      }

      const jsonData = await response.json();

      setCheckEmailVerifiedStatus(jsonData?.verified);
      setAffData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCampaginDetails = async (id) => {
    if (!id) return;
    try {
      const campageinId = id;
      const url = `${URL}/campaign/${campageinId}`;
      const Camdata = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCampaignData(Camdata.data);
      setCampaginName(Camdata.data.name);
    } catch (error) {
      console.error("Error fetching CampaginData data:", error);
    }
  };
  const fetchAffiliateData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(url1, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setAffiliateData(jsonData);

      await fetchCampaginDetails(jsonData?.iframe_campaign_id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchWalletData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(urlWallet, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setWalletData(jsonData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function init() {
    try {
      setLoading(true);
      await fetchAffiliateData();
      await fetchWalletData();
      await checkEmailVerified();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await axios.get(
          `${URL2}/api/affiliates/additional_info`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data) {
          setCardData(response.data);
        }
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCardData();
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(url_image, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Profile photo updated successfully");
        // Update local state immediately
        const reader = new FileReader();
        reader.onload = (e) => {
          setMainProfile(e.target.result);
        };
        reader.readAsDataURL(file);
        
        // Refresh data after short delay
        setTimeout(() => {
          checkEmailVerified();
        }, 1000);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error updating profile photo. Please try again.");
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const calculatedTotalCount = data.reduce(
        (acc, item) => acc + (item.count || 0),
        0
      );
      setTotalCount(calculatedTotalCount);
    }
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      userId: user1?._id,
      affiliate,
      nameBeni,
      last,
      username,
      companyName,
      phoneNumber,
      address2,
      address1,
      age,
      skype,
      postCode,
      state,
      city,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/userDetail/userDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success("Form data submitted successfully!");
      } else {
        toast.error("Error submitting form data. Please try again.");
      }
    } catch (error) {
      toast.error("Error submitting form data. Please try again.");
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  const OverviewForm = () => {
    const [editable, setEditable] = useState(false);
    const [
      editAffiliateAdditionalInfoData,
      seteditAffiliateAdditionalInfoData,
    ] = useState({
      affiliate_id: "string",
      verticals: [],
      traffic_sources: [],
      traffic_source_country: "",
      company_name: "",
      profile: "",
      experience: "",
      company_address: "",
      contact_number: "",
      email_id: "",
      linkedin: "",
      news_paper: "",
      telegram: "",
      skype_id: "",
      affiliate_country: "",
      website_address: "",
      youtube_channel_link: "",
      pinterest_profile_link: "",
      tiktok_profile_link: "",
      twitter_handle: "",
      linkedin_profile_link: "",
      telegram_channel_link: "",
      snapchat_handle: "",
      reddit_profile_link: "",
    });

    const verticals = [
      "SWEEPS",
      "SEARCH_ARBITRAGE",
      "LEAD_GEN",
      "DATING",
      "APPS",
      "IGAMING",
      "STREAMING",
      "TRAVEL",
      "CARRIER_BILLING",
      "BIZOPP_OFFERS",
      "ECOM_AFFILIATE_OFFERS",
      "ECOM_STORE_OWNER",
      "CONTENT_MARKETING",
      "COD",
      "GAMING",
      "CONTENT_ARBITRAGE",
      "INSURANCE",
      "CRYPTO",
      "FINANCE",
      "HOME_IMPROVEMENT",
      "HEALTH_AND_BEAUTY",
      "NUTRA",
    ];
    const marketingChannels = [
      "SEO",
      "PAID_SEARCH",
      "NATIVE",
      "META_TS_FB_INSTA",
      "YOUTUBE_TS",
      "PINTEREST_TS",
      "TIKTOK_TS",
      "EMAIL_TS",
      "PUSH_TS",
      "DISPLAY_DESK_MOB",
      "TWITTER",
      "LINKEDIN",
      "TELEGRAM",
      "SNAPCHAT",
      "POPS_REDIRECT",
      "SMS",
      "REDDIT",
      "NEWSPAPER",
    ];

    useEffect(() => {
      const getAffiliateAdditionalInfo = async () => {
        try {
          const response = await axios.get(url_get_aff_info, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
          console.log(response.data);

          const modifiedData = response.data;

          seteditAffiliateAdditionalInfoData(modifiedData);
          console.log("Modified JSON:", modifiedData);
        } catch (error) {
          console.log(error.message);
        }
      };

      getAffiliateAdditionalInfo();
    }, [editable]);
    const toggleEditable = () => {
      setEditable(!editable);
    };
    const formOnchangeHandler = (event) => {
      event.preventDefault();
      if (!editable) {
        return;
      }
      const { name, value, checked } = event.target;

      if (name === "traffic_sources" || name === "verticals") {
        const propArray =
          name === "traffic_sources" ? "traffic_sources" : "verticals";
        seteditAffiliateAdditionalInfoData((prevCopy) => ({
          ...prevCopy,
          [propArray]: checked
            ? [...prevCopy[propArray], value]
            : prevCopy[propArray].filter((el) => el !== value),
        }));
      } else {
        seteditAffiliateAdditionalInfoData((prevCopy) => ({
          ...prevCopy,
          [name]: value,
        }));
      }
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault();
      if (!editable) {
        return;
      }
      try {
        const affiliateId = affData.affiliate_id;
        const updatedEditAffiliateData = {
          ...editAffiliateAdditionalInfoData,
          affiliate_id: "4b5562fb788d4cdf99b0dcd5d461a8b2",
        };

        const response = await axios.post(
          url_get_aff_info,
          updatedEditAffiliateData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        seteditAffiliateAdditionalInfoData(response?.data);
        toast.success("Profile saved successfully", 2000);
        setEditable(false);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    };
    const copyToClipboard = (textcopy) => {
      if (textcopy !== "") {
        navigator.clipboard
          .writeText(textcopy)
          .then(() => {
            toast.success("successfully copied", 2000);
          })
          .catch((err) => {
            console.error("Error copying text to clipboard:", err);
          });
      }
    };
    return (
      <>
        <Box
          style={{ maxWidth: "1200px", backgroundColor: "#EDF2F7" }}
          className={classes.boxstyleForm}
        >
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="account">Affiliate ID:</FormLabel>
                  <TextField
                    type="text"
                    id="affiliate"
                    min={0}
                    value={affData.affiliate_id}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {" "}
                <FormControl>
                  <FormLabel htmlFor="name"> Name:</FormLabel>
                  <TextField
                    type="text"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                    min={0}
                    value={affData.name}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="profile">Profile : </FormLabel>
                  <TextField
                    type="text"
                    id="profile"
                    placeholder="Enter Profile "
                    name="profile"
                    value={editAffiliateAdditionalInfoData.profile}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="experience">Experinece :</FormLabel>
                  <TextField
                    type="text"
                    id="experience"
                    placeholder="Enter experience "
                    name="experience"
                    value={editAffiliateAdditionalInfoData.experience}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="name">Company Name:</FormLabel>
                  <TextField
                    type="text"
                    id="companyname"
                    placeholder="Company Name"
                    name="company_name"
                    value={editAffiliateAdditionalInfoData.company_name}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {" "}
                <FormControl>
                  <FormLabel htmlFor="company_address">
                    Company Address{" "}
                  </FormLabel>
                  <TextField
                    name="company_address"
                    type="text"
                    id="company_address"
                    placeholder="Enter company address"
                    value={editAffiliateAdditionalInfoData.company_address}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="traffic_source_country">
                    Traffic Source Country
                  </FormLabel>
                  <TextField
                    type="text"
                    id="traffic_source_country"
                    placeholder="Enter traffic source country  "
                    name="traffic_source_country"
                    value={
                      editAffiliateAdditionalInfoData.traffic_source_country
                    }
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="affiliate_country">
                    Affiliate Country :
                  </FormLabel>
                  <TextField
                    type="text"
                    id="affiliate_country"
                    placeholder="Enter affiliate country"
                    name="affiliate_country"
                    value={editAffiliateAdditionalInfoData.affiliate_country}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="bio">Bio:</FormLabel>
                  <TextField
                    type="text"
                    id="bio"
                    placeholder="Enter bio"
                    name="profile"
                    value={affData.bio}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {verified ? (
                  <Button
                    variant="contained"
                    color="success"
                    className={classes.button + "  send-email-btn "}
                    sx={{
                      padding: "10px 0",
                      width: "218px",
                      marginTop: "25px",
                    }}
                  >
                    You are Varified now !
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendVerificationEmail}
                    className={classes.button + "  send-email-btn "}
                    sx={{
                      padding: "10px 0",
                      width: "218px",
                      marginTop: "25px",
                    }}
                  >
                    Send Verification Email
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container mt={1}>
              <Grid
                item
                md={6}
                xs={12}
                height="auto"
                className="d-flex justify-content-center  gap-3 flex-column p-2"
              >
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className="verticals-check-box-container p-2">
                    <div className="form-group">
                      <label>Verticals</label>
                      <Box sx={{ height: "230px", overflowY: "auto" }}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          {verticals.map((vertical, index) => (
                            <Grid key={index} item xs={12} md={6}>
                              <Box className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      name={"verticals"}
                                      onChange={formOnchangeHandler}
                                      checked={
                                        editAffiliateAdditionalInfoData.verticals.indexOf(
                                          vertical
                                        ) !== -1
                                      }
                                      value={vertical}
                                    />
                                  }
                                  label={vertical}
                                  sx={{
                                    display: "block",
                                    margin: "0 auto",
                                    bgcolor: editable ? "none" : "#e6e6e6",
                                  }}
                                />
                              </Box>
                            </Grid>
                          ))}
                          <Grid item xs={12} md={12}>
                            <Box className="">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name={"verticals"}
                                    value={"PAYPER_CALL"}
                                    onChange={formOnchangeHandler}
                                    checked={
                                      editAffiliateAdditionalInfoData.verticals.indexOf(
                                        "PAYPER_CALL"
                                      ) !== -1
                                    }
                                    disabled={!editable}
                                  />
                                }
                                label={"PAYPER_CALL"}
                                sx={{
                                  display: "block",
                                  margin: "0 auto",
                                  bgcolor: editable ? "none" : "#e6e6e6",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </Box>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                    [theme.breakpoints.up("md")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className="verticals-check-box-container p-2">
                    <div className="form-group">
                      <label>Traffic Source</label>
                      <Box sx={{ height: "230px", overflowY: "auto" }}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          {marketingChannels.map((marketingChannels, index) => (
                            <Grid key={index} item xs={12} md={6}>
                              <Box className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      name={"traffic_sources"}
                                      onChange={formOnchangeHandler}
                                      value={marketingChannels}
                                      checked={
                                        editAffiliateAdditionalInfoData.traffic_sources.indexOf(
                                          marketingChannels
                                        ) !== -1
                                      }
                                    />
                                  }
                                  label={marketingChannels}
                                  sx={{
                                    display: "block",
                                    margin: "0 auto",
                                    bgcolor: editable ? "none" : "#e6e6e6",
                                  }}
                                />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                height="auto"
                className="d-flex justify-content-center  gap-3 flex-column p-2"
              >
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className=" p-2">
                    <Typography fontWeight={"bold"}>Contact :</Typography>
                    <Box
                      sx={{ height: "230px", overflowY: "auto" }}
                      className="px-3"
                    >
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              bgcolor: editable ? "none" : "#e6e6e6",
                            }}
                            placeholder="Enter phone number"
                            name="contact_number"
                            value={
                              editAffiliateAdditionalInfoData.contact_number
                            }
                            onChange={formOnchangeHandler}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.contact_number
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              target="_blank"
                              href={`tel:${editAffiliateAdditionalInfoData.contact_number}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter email address"
                            name="email_id"
                            value={editAffiliateAdditionalInfoData.email_id}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.email_id
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={`mailto:${editAffiliateAdditionalInfoData.email_id}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TelegramIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter telegram username"
                            name="telegram"
                            value={editAffiliateAdditionalInfoData.telegram}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.telegram
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              target="_blank"
                              href={`https://t.me/${editAffiliateAdditionalInfoData.telegram}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NewspaperIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your newspaper"
                            name="news_paper"
                            value={editAffiliateAdditionalInfoData.news_paper}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.news_paper
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={editAffiliateAdditionalInfoData.news_paper}
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className=" p-2">
                    <Typography fontWeight={"bold"}>Social Links :</Typography>
                    <Box
                      sx={{ height: "230px", overflowY: "auto" }}
                      className="px-3"
                    >
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={skypeSvg}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your skype id"
                            name="skype_id"
                            value={editAffiliateAdditionalInfoData.skype_id}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.skype_id
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={editAffiliateAdditionalInfoData.skype_id}
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LanguageIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your website url"
                            name="website_address"
                            value={
                              editAffiliateAdditionalInfoData.website_address
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.website_address
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.website_address
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TelegramIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter tel channel url"
                            name="telegram_channel_link"
                            value={
                              editAffiliateAdditionalInfoData.telegram_channel_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.telegram_channel_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.telegram_channel_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <YouTubeIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter youtube channel link"
                            name="youtube_channel_link"
                            value={
                              editAffiliateAdditionalInfoData.youtube_channel_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.youtube_channel_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.youtube_channel_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3 "
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LinkedInIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter linkedin profile url"
                            name="linkedin_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.linkedin_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.linkedin_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.linkedin_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PinterestIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter pinterest profile link"
                            name="pinterest_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.pinterest_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.pinterest_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.pinterest_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TwitterIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter  twitter profile link"
                            name="twitter_handle"
                            value={
                              editAffiliateAdditionalInfoData.twitter_handle
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.twitter_handle
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.twitter_handle
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={TickTockIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter tiktok profile link"
                            name="tiktok_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.tiktok_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.tiktok_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.tiktok_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={SnapChatIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter snapchat profile link"
                            name="snapchat_handle"
                            value={
                              editAffiliateAdditionalInfoData.snapchat_handle
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.snapchat_handle
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.snapchat_handle
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <RedditIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter reddit profile link"
                            name="reddit_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.reddit_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon sx={{ fontSize: "20px" }} />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.reddit_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ backgroundColor: "#f0f0f0", padding: "16px" }}>
              <Grid container>
                <Grid item md={6} xs={6}></Grid>
                <Grid item md={6} xs={6}>
                  <Box className="d-flex justify-content-end gap-3">
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        width: "25%",
                      }}
                      onClick={toggleEditable}
                    >
                      {!editable ? "Edit" : "Cancle"}
                    </Button>
                    {editable ? (
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          width: "25%",
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </>
    );
  };

  const MyCard = () => {
    const matches = useMediaQuery('(max-width:600px)');
    const [editable, setEditable] = useState(false);
    const [searchCountry, setSearchCountry] = useState('');
    const [tempCardData, setTempCardData] = useState({...cardData});
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);
    const [locationError, setLocationError] = useState('');

    const filteredCountries = COUNTRIES_OPTIONS.filter(country =>
      country.toLowerCase().includes(searchCountry.toLowerCase())
    );

    useEffect(() => {
      if (editable) {
        setTempCardData({...cardData});
      }
    }, [editable]);

    const getCurrentLocation = () => {
      setIsLoadingLocation(true);
      setLocationError('');

      if (!navigator.geolocation) {
        setLocationError('Geolocation is not supported by your browser');
        setIsLoadingLocation(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            
            // Using OpenStreetMap's Nominatim service for reverse geocoding
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`,
              {
                headers: {
                  'Accept-Language': 'en'
                }
              }
            );

            const address = response.data;
            const city = address.address.city || address.address.town || address.address.village || '';
            const state = address.address.state || '';
            const country = address.address.country || '';
            const locationString = `${city}, ${state}, ${country}`;
            
            setTempCardData(prev => ({
              ...prev,
              currentLocation: locationString,
              locationCoordinates: {
                latitude,
                longitude
              },
              city: city,
              state: state,
              country: country
            }));
            setIsLoadingLocation(false);
          } catch (error) {
            console.error('Error getting location details:', error);
            setLocationError('Error fetching location details');
            setIsLoadingLocation(false);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationError('Error getting your location. Please make sure location services are enabled.');
          setIsLoadingLocation(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        // If coordinates are not set but we have them in the form, add them
        if (!tempCardData.locationCoordinates && tempCardData.currentLocation) {
          const [latitude, longitude] = tempCardData.currentLocation.split(',').map(coord => parseFloat(coord.trim()));
          if (!isNaN(latitude) && !isNaN(longitude)) {
            tempCardData.locationCoordinates = { latitude, longitude };
          }
        }

        const dataToSend = {
          ...tempCardData,
          coordinates: tempCardData.locationCoordinates ? 
            `${tempCardData.locationCoordinates.latitude},${tempCardData.locationCoordinates.longitude}` : '',
          location: tempCardData.currentLocation || '',
          city: tempCardData.city || '',
          state: tempCardData.state || '',
          country: tempCardData.country || ''
        };

        const response = await axios.post(
          `${URL2}/api/affiliates/additional_info`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCardData(tempCardData);
        toast.success("Card details updated successfully");
        setEditable(false);
      } catch (error) {
        console.error("Error updating card details:", error);
        toast.error("Error updating card details");
      }
    };

    const handleChange = (e, name) => {
      const value = e.target.value;
      
      if (Array.isArray(tempCardData[name])) {
        setTempCardData(prev => ({
          ...prev,
          [name]: typeof value === 'string' ? value.split(',') : value
        }));
      } else {
        setTempCardData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    };

    const handleCancel = () => {
      setTempCardData({...cardData});
      setEditable(false);
  };

  return (
      <Box sx={{ 
        mt: 3, 
        p: {
          xs: 2,
          sm: 3
        }, 
        bgcolor: '#fff', 
        borderRadius: 2, 
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        '& .MuiFormControl-root': {
          width: '100%',
          mb: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            '&:hover fieldset': {
              borderColor: '#1a237e',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1a237e',
            }
          }
        }
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h6">My Card</Typography>
          {!editable && (
            <IconButton onClick={() => setEditable(true)}>
              <EditIcon />
            </IconButton>
          )}
      </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Content Creation Experience</InputLabel>
                <Select
                  value={editable ? tempCardData.contentCreationExperience : cardData.contentCreationExperience}
                  onChange={(e) => handleChange(e, 'contentCreationExperience')}
                  label="Content Creation Experience"
                >
                  {EXPERIENCE_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Preferred Types of Deals</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.preferredTypesOfDeals : cardData.preferredTypesOfDeals}
                  onChange={(e) => handleChange(e, 'preferredTypesOfDeals')}
                  label="Preferred Types of Deals"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {DEALS_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.preferredTypesOfDeals : cardData.preferredTypesOfDeals).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Primary Audience Age Range</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.primaryAudienceAgeRange : cardData.primaryAudienceAgeRange}
                  onChange={(e) => handleChange(e, 'primaryAudienceAgeRange')}
                  label="Primary Audience Age Range"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {AGE_RANGE_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.primaryAudienceAgeRange : cardData.primaryAudienceAgeRange).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Primary Audience Gender</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.primaryAudienceGender : cardData.primaryAudienceGender}
                  onChange={(e) => handleChange(e, 'primaryAudienceGender')}
                  label="Primary Audience Gender"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {GENDER_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.primaryAudienceGender : cardData.primaryAudienceGender).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Average Engagement Rate</InputLabel>
                <Select
                  value={editable ? tempCardData.averageEngagementRate : cardData.averageEngagementRate}
                  onChange={(e) => handleChange(e, 'averageEngagementRate')}
                  label="Average Engagement Rate"
                >
                  {ENGAGEMENT_RATE_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Content Types</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.contentTypes : cardData.contentTypes}
                  onChange={(e) => handleChange(e, 'contentTypes')}
                  label="Content Types"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {CONTENT_TYPES_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.contentTypes : cardData.contentTypes).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Content Niches</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.contentNiches : cardData.contentNiches}
                  onChange={(e) => handleChange(e, 'contentNiches')}
                  label="Content Niches"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {CONTENT_NICHES_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.contentNiches : cardData.contentNiches).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Content Creation Frequency</InputLabel>
                <Select
                  value={editable ? tempCardData.contentCreationFrequency : cardData.contentCreationFrequency}
                  onChange={(e) => handleChange(e, 'contentCreationFrequency')}
                  label="Content Creation Frequency"
                >
                  {FREQUENCY_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Currency</InputLabel>
                <Select
                  value={editable ? tempCardData.currency : cardData.currency}
                  onChange={(e) => handleChange(e, 'currency')}
                  label="Currency"
                >
                  {CURRENCY_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label="Current Location"
                  name="currentLocation"
                  value={editable ? tempCardData.currentLocation : cardData.currentLocation}
                  onChange={(e) => handleChange(e, 'currentLocation')}
                  disabled={!editable || isLoadingLocation}
                  error={!!locationError}
                  helperText={locationError}
                  InputProps={{
                    endAdornment: editable && (
                      <InputAdornment position="end">
                        <IconButton 
                          onClick={getCurrentLocation}
                          disabled={isLoadingLocation}
                          sx={{ mr: -1 }}
                        >
                          {isLoadingLocation ? (
                            <CircularProgress size={24} />
                          ) : (
                            <MyLocationIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {tempCardData.locationCoordinates && (
                  <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
                    Coordinates: {tempCardData.locationCoordinates.latitude.toFixed(6)}, {tempCardData.locationCoordinates.longitude.toFixed(6)}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!editable}>
                <InputLabel>Audience Countries</InputLabel>
                <Select
                  multiple
                  value={editable ? tempCardData.audienceCountries : cardData.audienceCountries}
                  onChange={(e) => handleChange(e, 'audienceCountries')}
                  label="Audience Countries"
                  renderValue={(selected) => selected.join(', ')}
                  onOpen={() => setSearchCountry('')}
                >
                  <Box sx={{ p: 1, position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 1 }}>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder="Search countries..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchCountry(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      value={searchCountry}
                    />
                  </Box>
                  {filteredCountries.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={(editable ? tempCardData.audienceCountries : cardData.audienceCountries).indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                      </Grid>
            {editable && (
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                justifyContent: 'flex-end', 
                mt: 2,
                flexDirection: {
                  xs: 'column',
                  sm: 'row'
                }
              }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                  fullWidth={matches}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth={matches}
                >
                  Save All Changes
                </Button>
              </Box>
            )}
          </Grid>
        </form>
      </Box>
    );
  };

  return (
    <Box className={classes.pageContainer}>
      <Paper className={classes.mainCard}>
        <Box className={classes.profileSection}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={2}>
              <Box className={classes.profileImageContainer}>
                <img
                  src={mainProfile || account.photoURL}
                  alt="Profile"
                  className={classes.profileImage}
                />
                <input
                  type="file"
                  id="profile-upload"
                  hidden
                  onChange={handleImageChange}
                />
                <label htmlFor="profile-upload">
                  <Button
                    component="span"
                    className={`${classes.button} ${classes.memberButton}`}
                    startIcon={<AccountCircleIcon />}
                    size="small"
                    fullWidth
                  >
                    Change Photo
                  </Button>
                </label>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={classes.profileInfo}>
                <Typography className={classes.profileName}>
                  {user1?.data?.name}
                </Typography>
                <Box className={classes.profileEmail}>
                  {verified ? (
                    <Box className={classes.verifiedBadge}>
                      <VerifiedUserIcon fontSize="small" />
                      Verified
                    </Box>
                  ) : (
                    <Box className={classes.unverifiedBadge}>
                      <UnpublishedIcon fontSize="small" />
                      Unverified
                    </Box>
                  )}
                  <Typography>{user1.data.email}</Typography>
                </Box>
                <Box className={classes.profileLocation}>
                  <AddLocationIcon />
                  <Typography>India</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className={classes.profileButtons}>
                <Button
                  className={`${classes.button} ${classes.memberButton}`}
                  fullWidth={matches}
                >
                  {`Member since ${date}`}
                </Button>
                <Button
                  className={`${classes.button} ${classes.resetButton}`}
                  fullWidth={matches}
                >
                  Reset Password
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.statsContainer}>
          {/* ... stats cards ... */}
        </Box>

        <Box className={classes.tabsContainer}>
          <Grid container spacing={1} sx={{ flexWrap: 'nowrap' }}>
            {['overview', 'managers', 'campaign', 'postback', 'payouts', 'company', 'billing'].map((tab) => (
              <Grid item key={tab}>
                <Button
                  className={`${classes.tabButton} ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box className={classes.formSection}>
          {activeTab === "overview" && (
            <Box>
              <OverviewForm />
              <MyCard />
            </Box>
          )}
      {activeTab === "managers" && <ManagersTable />}
      {activeTab === "campaign" && <Campaign />}
        </Box>
      </Paper>

      <ToastContainer />
    </Box>
  );
}

export default UserDetails;
